.sidebar {
  position: fixed !important;
  height: 100vh;
  overflow: hidden !important;
}

.side_bar_menu_list {
  height: 95vh;
  padding-bottom: 50px;
  overflow-y: auto;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
}

.ant-menu-item-icon {
  font-size: 24px !important;
  color: var(--white-color);
}

.ant-menu-title-content {
  color: #2a313c;
}

.sidebar_top {
  margin-top: 42px;
  width: 100%;
}

.sidebar_top .sidebar_menu_item {
  display: flex;
  align-items: center;
}

.sidebar_top .sidebar_menu_item span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar_top .sidebar_menu_item button {
  flex-shrink: 0;
  background: none;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  margin-left: .25rem;
  transition: color .1s ease;
}

.sidebar_top .sidebar_menu_item button:hover {
  color: #d82a2a;
}

.side_bar_menu_list hr {
  border: 1px solid #475467;
}

.ant-layout-sider {
  min-width: 220px !important;
  padding: 12px;
}

.ant-menu {
  background: transparent !important;
}

.ant-menu .ant-menu-item {
  color: var(--white-color);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.sidenav_user {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.sidenav_user span {
  font-size: var(--small-font-size);
  color: var(--description-color);
}

.sidenav_user p {
  font-size: var(--small-font-size);
  color: var(--white-color);
  font-weight: 500;
}

.fixed-header {
  position: relative !important;
  top: 0;
  z-index: 999;
  margin-left: 0 !important;
}

.ant-layout-header {
  background: #2e3349;
  display: flex;
  align-items: center;
}

.topbar_nav_item {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  gap: 30px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.topbar_nav_item .logo {
  display: none;
}

.demo-logo-vertical.logo{
  height: 50px;
  display: flex;
  align-items: center;
}

.top_nav_list {
  display: flex;
  align-items: center;
  gap: 30px;
}

.topbar_nav_item .user {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header_toggle_btn {
  margin-left: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  min-width: 50px;
  min-height: 50px;
  background: #0000001a;
}

.top_nav_sell_btn {
  padding: 5px 10px;
  background: #1a202c;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: normal;
  border-radius: 8px;
  min-width: max-content;
}

:where(.css-dev-only-do-not-override-byeoj0) a:hover {
  color: var(--white-color) !important;
}

.top_nav_sell_btn img {
  min-width: 10px !important;
  min-height: 10px !important;
}

.header_toggle_btn .icons {
  font-size: 30px !important;
  color: var(--white-color);
}

.header_toggle_btn:hover {
  background: none !important;
}

.right_nav_item .T_mobile {
  padding-left: 30px;
  border-left: 1px solid var(--border-color);
  line-height: normal;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  position: fixed;
  background: #2e3349 !important;
}

li.ant-menu-item {
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

li.ant-menu-item.ant-menu-item-active {
  background: #4576fc54 !important;
  color: var(--white-color);
}

li.ant-menu-item span,
li.ant-menu-item.ant-menu-item-active .ant-menu-item-icon,
li.ant-menu-item.ant-menu-item-active span {
  color: var(--white-color);
}

li.ant-menu-item.ant-menu-item-selected {
  padding-left: 12px !important;
  background: var(--primary-color);
  color: var(--white-color);
}

.body_layout {
  margin-left: 220px;
}

.nav_credits_area{
  padding: 12px;
  border: 1px solid var(--primary-color);
  margin-top: 30px;
  border-radius: 8px;
}

.nav_credits_area .info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.nav_credits_area .btn{
  width: 100%;
  background: transparent;
  border: 1px solid var(--primary-color);
}

.nav_credits_area .btn{
  background: transparent;
  border: 1px solid var(--primary-color);
}

.nav_credits_area .btn:hover{
  color: var(--white-color);
  background: var(--secondary-color)
}

.sidebar_bottom{
  width: 100%;
}

@media screen and (max-width: 1220px) {
  .header_toggle_btn {
    display: block;
  }

  .body_layout {
    margin-left: 0;
  }

  .ant-layout.hide_body_layout.css-byeoj0,
  .ant-layout.hide_body_layout.css-dev-only-do-not-override-byeoj0 {
    margin-left: 220px;
  }

  aside.ant-layout-sider {
    left: -100%;
  }

  aside.ant-layout-sider.ant-layout-sider-dark.hide_side_navbar {
    left: 0;
    z-index: 9999;
  }

  .topbar_nav_item .logo {
    display: block;
  }

  .topbar_nav_item {
    justify-content: space-between !important;
  }
}

@media screen and (max-width: 576px) {
  .topbar_nav_item .user p {
    display: none;
  }
}
